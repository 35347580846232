<template>
  <div>
    <div class="backgroundOV"></div>
    <v-card outlined>
      <v-card-title class="titleLarge"
        >Zahlverständnis – Übergang Diagnose</v-card-title
      >
    </v-card>
    <br />
    <br />
    <v-card outlined>
      <v-card-title class="titleSmaller"
        >Welche Facetten des Hintergrundwissens konnten beim Zahlverständnis
        ausgemacht werden?
      </v-card-title>
      <v-card-text>
        Das Hintergrundwissen im Bereich Zahlverständnis gliedert sich in die
        folgenden drei Facetten:
        <ol>
          <li>Grundvorstellungen von Zahlen</li>
          <li>Darstellungen vernetzen</li>
          <li>Zusammenhänge zwischen Zahlen</li>
        </ol>
        Das Wissen um diese drei Teilbereiche ist vor allem auch für die
        Diagnose und Förderung der Kinder wichtig, um zum einen diagnostizieren
        zu können, wo genau beispielsweise Probleme auftreten und daran
        anschließend eine passgenaue Förderung anzustreben.
      </v-card-text>
    </v-card>
    <br />
    <br />
    <v-card outlined>
      <v-card-title class="titleSmaller">
        Was erwartet Sie im Bereich der Diagnose und Förderung im
        Zahlverständnis?
      </v-card-title>
      <v-card-text>
        In drei Aufgaben betrachten Sie gleich Lösungen von Kindern, um
        <ul>
          <li>
            das ordinale und kardinale Zahlverständnis zu diagnostizieren.
          </li>
          <li>Fehler im Bereich des Zählens zu erkennen und zu beschreiben.</li>
          <li>Förderaufgaben für ein Kind diagnosegeleitet auszuwählen.</li>
        </ul>
      </v-card-text>
    </v-card>

    <AppBottomNavZV
      next="/zahlverstaendnis/diagnose-und-foerderung"
      back="/zahlverstaendnis/hintergrundwissen/zusammenhaenge"
    />
  </div>
</template>

<script>
import AppBottomNavZV from "@/common/AppBottomNavZV";
export default {
  components: {
    AppBottomNavZV,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
